






















































































































import { Vue, Component } from "vue-property-decorator";
import AlertService from "../../../common-app/service/AlertService";

@Component
export default class List extends Vue {
  public currentDate: Date = new Date();
}
